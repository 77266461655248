// components/BarChart.js
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCancelledPassengerAction } from '../../redux/action';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

const CancelledPassengerChart = () => {
    const data = useSelector(state => state.cancelledP)

    const [arrived, setArrived] = useState(0)

    Chart.register(CategoryScale);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCancelledPassengerAction())
        setArrived(data?.arrived?.count)
    }, [])
    const [chartData, setChartData] = useState({labels: [], datasets: []})
    useEffect(() => {
        if (data) {
            setChartData({
                labels: data?.offers?.map((d) => d.date),
                datasets: [
                    {
                        label: "Trip",
                        data: data?.offers?.map((d) => d.count),
                        // backgroundColor: [],
                        borderColor: "#6F52EA",
                        backgroundColor: '#6F52EA',
                        borderWidth: 1
                    }
                ]
            })
        }
    }, [data]);
    return (
        <div style={{ width: '85%' }}>
            <h4>{arrived} müştəri sürücü müştəriyə çatdıqdan sonra ləğv edib</h4>

            <Bar
                data={chartData}
                options={{
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    );
};
export default CancelledPassengerChart