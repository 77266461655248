import { format, startOfWeek, endOfWeek, set, startOfMonth, addDays } from "date-fns";
const initialState = {
    columns2: [
        {
            name: 'Sürücü',
            selector: row => row?.driver?.name + " " + row?.driver?.lastname,
            sortable: true,
            compact: true,
        },
        {
            name: 'Telefon nömrəsi',
            selector: row => row?.driver?.mobileNum,
            sortable: true,
            compact: true
        },
        {
            name: 'Vəziyyət',
            selector: row => row?.driver?.status,
            sortable: true,
            compact: true,
            cell: (row) => (
                <div className={row?.driver?.status == 1 ? "cell-item" : "cell-item red"}>{row?.driver?.status == 1 ? 'Cavab gözlənilir.' : 'Qəbul edilmədi'}</div>
            )
        }
    ],
    supportCustomstyle: {
        rows: {
            style: {
                minHeight: '70px',
                cursor: 'pointer',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#ededed',
                minHeight: '52px',
                borderBottomWidth: '1px',
                borderBottomColor: 'gray',
                borderBottomStyle: 'solid',
                borderRadius: '10px'
            },
            denseStyle: {
                minHeight: '32px',
            },
        },
        headCells: {
            style: {
                fontSize: '16px',
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                margin: '10px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                margin: '10px',
            },
        },
    },
    customStyles: {
        rows: {
            style: {
                minHeight: '70px',
                cursor: 'pointer',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#ededed',
                minHeight: '52px',
                borderBottomWidth: '1px',
                borderBottomColor: 'gray',
                borderBottomStyle: 'solid',
                borderRadius: '10px'
            },
            denseStyle: {
                minHeight: '32px',
            },
        },
        headCells: {
            style: {
                fontSize: '16px',
                paddingLeft: '8px',
                paddingRight: '8px',
                margin: '10px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                margin: '10px',
            },
        },
    },
    mapOptins: [
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#eeeeee",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#9e9e9e",
                },
            ],
        },
    ],
    customMarker: {
        path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
        fillColor: "#6F52EA",
        fillOpacity: 2,
        strokeWeight: 1,
        rotation: 0,
        scale: 1,
    },
    data: [],
    data2: [],
    completedTripsT: [],
    week_string: "Indiki həftə",
    paymentList: [],
    userInfo: [],
    tarif: [],
    fleetPayment: [],
    markers: [],
    markers2: [],
    markerDetails: [],
    supportData: [],
    searchValue: [],
    driverById: [],
    FleetToDriver: true,
    week: Math.ceil((new Date().getDate()) / 7),
    month: [
        "Yanvar",
        "Fevral",
        "Mart",
        "Aprel",
        "May",
        "Iyun",
        "Iyul",
        "Avqust",
        "Sentyabr",
        "Oktyabr",
        "Noyabr",
        "Dekabr"
    ],
    changedMonth: format(new Date(), "dd.MM.yyyy").split(".")[1] - 1,
    bidToFleetWeekValues: ["Indiki həftə", "1 həftə əvvəl", "2 həftə əvvəl", "3 həftə əvvəl"],
    checkPhoneNum: false,
    isPasswordCorrect: true,
    isUserExist: true,
    isMobilenumUsed: false,
    verticalOrHorizontal: "horizontal",
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                userInfo: action.payload.value,
            };
        case "CHANGED_MONTH":
            return {
                ...state,
                changedMonth: action.payload.value,
            };
        case "WEEK_STRING":
            return {
                ...state,
                week_string: action.payload.value,
            };

        case "GET_DRIVERS":
            return {
                ...state,
                data: action.payload.value,
            }
        case "GET_TARIFF":
            return {
                ...state,
                tarif: action.payload.value,
            }
        case "GET_NEW_ONLYDRIVER":
            return {
                ...state,
                onlyDrivers: action.payload.value,
            }
        case "GET_NEW_DRIVER":
            return {
                ...state,
                newDrivers: action.payload.value,
            }

        case "GET_INVITES":
            return {
                ...state,
                data2: action.payload.value,
            }
        case "GET_PASSENGERS":
            return {
                ...state,
                passengers: action.payload.value,
            }
        case "GET_ALL_OFFERS":
            return {
                ...state,
                allOffers: action.payload.value,
            }
        case "GET_COMPLETED":
            return {
                ...state,
                completedTrips: action.payload.value,
            }
        case "GET_COMPLETED_TABLE":
            return {
                ...state,
                completedTripsT: action.payload.value,
            }
        case "GET_REJECTED_OFFERS":
            return {
                ...state,
                rejectedOffers: action.payload.value,
            }
        case "GET_REJECTED_OFFERS_TABLE":
            return {
                ...state,
                rejectedOffersT: action.payload.value,
            }
        case "GET_REJECTED_OFFERS_D":
            return {
                ...state,
                rejectedOffersD: action.payload.value,
            }
        case "GET_REJECTED_OFFERS_TABLE_D":
            return {
                ...state,
                rejectedOffersTD: action.payload.value,
            }
        case "GET_CANCELLED_D":
            return {
                ...state,
                cancelledD: action.payload.value,
            }
        case "GET_CANCELLED_DT":
            return {
                ...state,
                cancelledDT: action.payload.value,
            }
        case "GET_CANCELLED_P":
            return {
                ...state,
                cancelledP: action.payload.value,
            }
        case "GET_CANCELLED_PT":
            return {
                ...state,
                cancelledPT: action.payload.value,
            }

        case "FLEET_TO_BID":
            return {
                ...state,
                FleetToDriver: action.payload.value,
            }

        case "GET_PAYMENTS":
            return {
                ...state,
                paymentList: action.payload.value,
            }

        case "GET_FLEET_PAYMENTS":
            return {
                ...state,
                fleetPayment: action.payload.value,
            }

        case "WHICH_WEEK":
            return {
                ...state,
                week: action.payload.value,
            }

        case "CHECK_PHONE_NUMBER":
            return {
                ...state,
                checkPhoneNum: action.payload.value
            }
        case "GET_ACTIVE_DRIVERS":
            return {
                ...state,
                activeDrivers: action.payload.value
            }

        case "IS_PASSWORD_CORRECT":
            return {
                ...state,
                isPasswordCorrect: action.payload.value

            }

        case "IS_USER_EXIST":
            return {
                ...state,
                isUserExist: action.payload.value
            }

        case "IS_MOBILENUMBER_USED":
            return {
                ...state,
                isMobilenumUsed: action.payload.value
            }

        case "VERTICAL_OR_HORIZONTAL":
            return {
                ...state,
                verticalOrHorizontal: action.payload.value
            }

        case "GET_SUPPORT_DATA":
            return {
                ...state,
                supportData: action.payload.value
            }

        case "GET_MARKERS":
            return {
                ...state,
                markers2: action.payload.value
            }

        case "SET_MARKER_DETAILS":
            return {
                ...state,
                markerDetails: action.payload.value
            }

        case "SET_SEARCH_VALUE":
            return {
                ...state,
                searchValue: action.payload.value
            }
        case "GET_DRIVER_BY_ID":
            return {
                ...state,
                driverById: action.payload.value
            }
        case "BID_TO_FLEET_WEEK_VALUE":
            return {
                ...state,
                bidToFleetWeekValues: action.payload.value,
            };
        default:
            return state;
    }
}



