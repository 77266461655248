import React, { useState, useEffect } from 'react';
import AllOffersChart from '../../components/DriverCharts/AllOffersChart'
import CompletedTripsChart from '../../components/DriverCharts/CompletedTripsChart'
import CompletedAllTable from '../../components/DriverTables/CompletedAllTable'
import RejectedOffersChart from '../../components/DriverCharts/RejectedOffersChart'
import RejectedTimeoutTable from '../../components/DriverTables/RejectedTimeoutTable'
import RejectedDriverOffersChart from '../../components/DriverCharts/RejectedDriverOffersChart'
import RejectedDriverTable from '../../components/DriverTables/RejectedDriverTable'
import CancelledDriverChart from '../../components/DriverCharts/CancelledDriverChart'
import CancelledDriverTable from '../../components/DriverTables/CancelledDriverTable'
import CancelledPassengerChart from '../../components/DriverCharts/CancelledPassengerChart'
import CancelledPassengerTable from '../../components/DriverTables/CancelledPassengerTable'

import './DriverReport.css';

const DriverReport = () => {

    return (
        <div className="chart-container" style={{ margin: 30, paddingBottom: 50 }}>
            <div className='chart-item'>
                <h3>Sürücülər tərəfindən göndərilən bütün təkliflər</h3>
                <AllOffersChart />
            </div>
            <div className='chart-item'>
                <h2>Tamamlanmış Sürüşlər</h2>
                <div className='chart-table'>
                    <CompletedTripsChart />
                    <div className='table-wrap'>
                        <CompletedAllTable />
                    </div>
                </div>
            </div>
            <div className='chart-item'>
                <h3>Sürücü tərəfindən qəbul olunmamış sürüşlər</h3>
                <div className='chart-table'>
                    <RejectedOffersChart />
                    <div className='table-wrap'>
                        <RejectedTimeoutTable />
                    </div>
                </div>
            </div>
            <div className='chart-item'>
                <h3>All Rejected - By Driver Offers</h3>
                <div className='chart-table'>
                    <RejectedDriverOffersChart />
                    <div className='table-wrap'>
                        <RejectedDriverTable />
                    </div>
                </div>
            </div>
            <div className='chart-item'>
                <h3>Sürücü tərəfindən ləğv olunmuş Sürüşlər</h3>
                <div className='chart-table'>
                    <CancelledDriverChart />
                    <div className='table-wrap'>
                        <CancelledDriverTable />
                    </div>
                </div>
            </div>
            <div className='chart-item'>
                <h3>Müştəri tərəfindən ləğv olunmuş sürüşlər</h3>
                <div className='chart-table'>
                    <CancelledPassengerChart />
                    <div className='table-wrap'>
                        <CancelledPassengerTable />
                    </div>
                </div>
            </div>

        </div>
    );
};
export default DriverReport