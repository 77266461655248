import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getCompletedTableAction } from '../../redux/action';

const CompletedAllTable = () => {
    const data = useSelector(state => state.completedTripsT)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCompletedTableAction())
      }, [])

    const columns = [
        {
            name: 'Sürücü',
            selector: row => row?.name,
            sortable: true,
            compact: true,
            center: true,
            cell: (row) => (
                <div className='cell-item'><span>{row?.name + " " + row?.lastname}</span></div>
            )
        },
        {
            name: 'Mobil Nömrə',
            selector: row => row?.mobileNum,
            sortable: true,
            center: true,
            compact: true
        },
        {
            name: 'Səyahət sayı',
            selector: row => row.count,
            sortable: true,
            center: true,
            compact: true
        },
    ]
    const customStyles = useSelector(state => state.supportCustomstyle)
    return (
        <DataTable
        columns={columns}
        data={data}
        pagination
        highlightOnHover={true}
        paginationPerPage={5}
        //customStyles={customStyles}
      />
    );
};
export default CompletedAllTable